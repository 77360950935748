import { get, post, patch, deleteAPI } from './general'
import { getCompanyId } from '../cookies'

export const getEDIRetailerDetails = (ediId, viewToken, params) => {
  return get(
    `/internal-apps/${ediId}/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const createSFTPCreds = (ediId, viewToken, data) => {
  return post(
    `/internal-apps/${ediId}/sftp-creds`,
    {
      'view-token': viewToken,
    },
    data
  )
}

export const updateInstall = (ediId, viewToken, data) => {
  return patch(
    `/internal-apps/${ediId}`,
    {
      'view-token': viewToken,
    },
    data
  )
}


export const getShopifyDetails = (viewToken, params) => {
  return get(
    `/internal-apps/shopify/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const getEDIDemoDetails = (viewToken, params) => {
  return get(
    `/internal-apps/demo-edi/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const listProductsForEDIDemo = (viewToken, params) => {
  return get(
    `/internal-apps/demo-edi/products`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const createTestOrder = (viewToken, data) => {
  return post(
    `/internal-apps/demo-edi/orders`,
    {
      'view-token': viewToken,
    },
    data
  )
}

export const retryDemoEDI = (viewToken, data) => {
  return post(
    `/internal-apps/demo-edi/retry`,
    {
      'view-token': viewToken,
    },
    data
  )
}


export const getWooCommerceDetails = (viewToken, params) => {
  return get(
    `/internal-apps/woocommerce/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const updateWooCommerceDetails = (viewToken, data) => {
  return patch(
    `/internal-apps/woocommerce/details`,
    {
      'view-token': viewToken,
    },
    data
  )
}

export const getAliExpressDetails = (viewToken, params) => {
  return get(
    `/internal-apps/aliexpress/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}


export const getAliExpressOAuth1 = (viewToken, params) => {
  return post(
    `/internal-apps/aliexpress/oauth/step1`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const getAliExpressOAuth2 = (params) => {
  return post(
    `/internal-apps/aliexpress/oauth/step2`,
    {},
    params
  )
}

export const addAliExpressProduct = (viewToken, data) => {
  return post(
    `/internal-apps/aliexpress/products`,
    {
      'view-token': viewToken,
    },
    data
  )
}
export const getShopifyOAuth1 = (params) => {
  return post(
    `/internal-apps/shopify/oauth/step1`,
    {},
    params
  )
}

export const getShopifyOAuth1WithViewToken = (viewToken, params) => {
  return post(
    `/internal-apps/shopify/oauth/step1-with-view-token`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const updateShopifyDetails = (viewToken, params) => {
  return patch(
    `/internal-apps/shopify/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const disconnectShop = (viewToken, params) => {
  return post(
    `/internal-apps/shopify/oauth/disconnect`,
    {
      'view-token': viewToken,
    },
    params
  )
}


export const getSharedEDIV1Details = (ediId, viewToken, params) => {
  return get(
    `/internal-apps/edi-v1/${ediId}/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const updateSharedEDIV1Details = (ediId, viewToken, data) => {
  return patch(
    `/internal-apps/edi-v1/${ediId}/details`,
    {
      'view-token': viewToken,
    },
    data
  )
}

export const listEDIFilesForSharedEDI = (ediId, viewToken, params) => {
  return get(
    `/internal-apps/edi-v1/${ediId}/files`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const deleteEDIFilesForSharedEDI = (ediId, viewToken, fileId, params) => {
  return deleteAPI(
    `/internal-apps/edi-v1/${ediId}/files/${fileId}`,
    {
      'view-token': viewToken,
    },
    params
  )
}


export const listTransactionSetsForSharedEDI = (ediId, viewToken, params) => {
  return get(
    `/internal-apps/edi-v1/${ediId}/transaction-sets`,
    {
      'view-token': viewToken,
    },
    params
  )
}


export const processTransactionSetsForSharedEDI = (ediId, tsId, viewToken, params) => {
  return post(
    `/internal-apps/edi-v1/${ediId}/transaction-sets/${tsId}/process`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const deleteTransactionSetsForSharedEDI = (ediId, tsId, viewToken, params) => {
  return deleteAPI(
    `/internal-apps/edi-v1/${ediId}/transaction-sets/${tsId}`,
    {
      'view-token': viewToken,
    },
    params
  )
}


export const getManagedSFTPServerDetails = (viewToken, params) => {
  return get(
    `/internal-apps/managed-sftp-server/details`,
    {
      'view-token': viewToken,
    },
    params
  )
}

export const createSFTPUserForManagedSFTP = (viewToken, body) => {
  return post(
    `/internal-apps/managed-sftp-server/users`,
    {
      'view-token': viewToken,
    },
    body
  )
}

export const listSFTPUserFilesForManagedSFTP = (viewToken, userName, params) => {
  return get(
    `/internal-apps/managed-sftp-server/users/${userName}/files`,
    {
      'view-token': viewToken,
    },
    params
  )
}
