import { useEffect, useState } from 'react'
import {
  listTransactionSetsForSharedEDI,
  processTransactionSetsForSharedEDI,
  deleteTransactionSetsForSharedEDI,
} from 'utils/api/internal-apps'

const getTsDisplayName = (code) => {
  switch (code) {
    case '810':
      return `Invoice (810)`
    case '850':
      return `Purchase Order (850)`
    case '855':
      return `Purchase Order Acknowledgement (855)`
    case '856':
      return `Advanced Shipping Notice (856)`
    case '860':
      return `Purchase Order Change Request (860)`
    case '865':
      return `Purchase Order Change Acknowledgement (865)`
    case '997':
      return `Functional Acknowledgement (997)`
    default:
      return code
  }
}

const getTsStatusBadge = (status) => {
  if (status === 'completed') {
    return (
      <span className="badge rounded-pill badge-subtle-success">Completed</span>
    )
  }
  if (status === 'pending_acknowledgement') {
    return (
      <span className="badge rounded-pill badge-subtle-warning">
        Pending Acknowledgement
      </span>
    )
  }
  if (status === 'pending') {
    return <span className="badge rounded-pill badge-subtle-info">Pending</span>
  }
  if (status === 'sent') {
    return <span className="badge rounded-pill badge-subtle-primary">Sent</span>
  }
  if (status === 'error') {
    return <span className="badge rounded-pill badge-subtle-danger">Error</span>
  }

  return (
    <span className="badge rounded-pill badge-subtle-secondary">{status}</span>
  )
}

const EDITSRow = ({ ts, companySlug, viewToken }) => {
  const [processingNow, setProcessingNow] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [hide, setHide] = useState(false)

  // processTransactionSetsForSharedEDI
  const onProcessNow = (e) => {
    e.preventDefault()
    setProcessingNow(true)
    processTransactionSetsForSharedEDI(companySlug, ts.id, viewToken, {}).then(
      (response) => {
        if (response.success) {
        }
        setProcessingNow(false)
        console.log(response)
      }
    )
  }

  const onDelete = (e) => {
    e.preventDefault()
    setDeleting(true)
    deleteTransactionSetsForSharedEDI(companySlug, ts.id, viewToken, {}).then(
      (response) => {
        if (response.success) {
          setHide(true)
        }
        setDeleting(false)
        console.log(response)
      }
    )
  }

  if (hide) {
    return null
  }

  return (
    <tr key={ts.id}>
      <td>{getTsDisplayName(ts.transactionSetCode)}</td>
      <td>{getTsStatusBadge(ts.status)}</td>
      <td>
        <span className="badge rounded-pill badge-subtle-primary">
          {ts.relatedTo}
        </span>
      </td>
      <td>
        <a href={'#'}>{ts.isaControlNum}</a>
      </td>
      <td>
        <button className="btn btn-link">View</button>
        {ts.status === 'pending' && (
          <button
            className="btn btn-link"
            disabled={processingNow}
            onClick={onProcessNow}
          >
            {processingNow ? (
              <div className="spinner-border text-primary" role="status"></div>
            ) : (
              'Process Now'
            )}
          </button>
        )}
        <button className="btn btn-link" onClick={onDelete}>
          Delete
        </button>
      </td>
    </tr>
  )
}

const EDITransactionSets = ({ companySlug, viewToken }) => {
  const [page, setPage] = useState(1)
  const [transactionSets, setTransactionSets] = useState([])

  const fetchTs = () => {
    listTransactionSetsForSharedEDI(companySlug, viewToken, {
      page,
    }).then((response) => {
      if (response.success) {
        setTransactionSets(response.data.transactionSets || [])
      }
    })
  }

  useEffect(() => {
    fetchTs()
  }, [])

  useEffect(() => {
    fetchTs()
  }, [page])

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>Transaction Set</th>
            <th>Status</th>
            <th>Related To</th>
            <th>EDI File</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactionSets.length > 0 ? (
            transactionSets.map((ts) => (
              <EDITSRow
                ts={ts}
                viewToken={viewToken}
                companySlug={companySlug}
              />
            ))
          ) : (
            <tr>
              <td colSpan="5">No transaction sets found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
/*
<tr>
            <td>Invoice (810)</td>
            <td>
              <span className="badge rounded-pill badge-subtle-success">
                Active
              </span>
            </td>
            <td>
              <span className="badge rounded-pill badge-subtle-primary">
                Order: 1004
              </span>
            </td>
            <td>
              <a href="#">ISA: 1234567890</a>
            </td>
            <td>
              <a href="#">View</a>
            </td>
          </tr>
*/

export default EDITransactionSets
