import SharedEDIV1Page from '../SharedPage/SharedEDIV1Page'

const HomeHardwareEDIPage = () => (
  <SharedEDIV1Page
    name="Home Hardware"
    logo="/imgs/apps/edi/home_hardware.jpeg"
    companySlug={'home_hardware'}
  />
)

export default HomeHardwareEDIPage
