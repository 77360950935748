import { useEffect, useState } from 'react'

import EDIDashboard from '../Dashboard/EDIDashboard'
import EDITransactionSets from '../TransactionSets/EDITransactionSets'
import EDIFiles from '../Files/EDIFiles'

import { getSharedEDIV1Details } from 'utils/api/internal-apps'

import './SharedEDIV1Page.css'

const SharedEDIV1Page = ({ name, logo, companySlug }) => {
  const [tab, setTab] = useState('dashboard')

  const viewToken = new URLSearchParams(window.location.search).get('viewToken')

  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState(null)
  const [appDetails, setAppDetails] = useState(null)

  const fetchDetails = (callback) => {
    getSharedEDIV1Details(companySlug, viewToken, {}).then((response) => {
      if (response.success) {
        setDetails({ ...response.data.details })
        setAppDetails(response.data.appDetails)
        setLoading(false)

        if (callback) {
          callback(response.data.details)
        }
      }
    })
  }

  useEffect(() => {
    fetchDetails()
  }, [])

  if (loading) {
    return (
      <div className="text-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid">
      <div className="row shared-edi-v1-title-row">
        <div className="col-12">
          <div className="shared-edi-v1-title">
            <div className="shared-edi-v1-logo">
              <img src={logo} alt={name} />
            </div>
            <div className="shared-edi-v1-title-inner">
              <h1>{name}</h1>
              <p className="text-muted">Manage your connection for {name}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div>
              <ul className="nav nav-tabs shared-edi-nav-tabs">
                <li className="nav-item">
                  <a
                    className={`nav-link ${tab === 'dashboard' ? 'active' : ''}`}
                    onClick={() => setTab('dashboard')}
                  >
                    Dashboard
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${tab === 'transaction-sets' ? 'active' : ''}`}
                    onClick={() => setTab('transaction-sets')}
                  >
                    Transaction Sets
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${tab === 'edi-files' ? 'active' : ''}`}
                    onClick={() => setTab('edi-files')}
                  >
                    EDI Files
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${tab === 'csv-files' ? 'active' : ''}`}
                    onClick={() => setTab('csv-files')}
                  >
                    CSV Files
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${tab === 'resources' ? 'active' : ''}`}
                    onClick={() => setTab('resources')}
                  >
                    Resources
                  </a>
                </li>
              </ul>
            </div>
            <div className="card-body">
              {tab === 'dashboard' ? (
                <EDIDashboard
                  companySlug={companySlug}
                  name={name}
                  viewToken={viewToken}
                  appDetails={appDetails}
                  details={details}
                  fetchDetails={fetchDetails}
                />
              ) : tab === 'transaction-sets' ? (
                <EDITransactionSets
                  companySlug={companySlug}
                  viewToken={viewToken}
                  appDetails={appDetails}
                  details={details}
                />
              ) : tab === 'edi-files' ? (
                <EDIFiles
                  companySlug={companySlug}
                  viewToken={viewToken}
                  appDetails={appDetails}
                  details={details}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SharedEDIV1Page
