import { useEffect, useState } from 'react'
import './Logo.css'
import { getLogoURL, setLogoURL } from 'utils/cookies'
import { getLogoByDomain } from 'utils/api/white-labeling'

const Logo = ({ size, style = {} }) => {
  const [logo, setLogo] = useState(null)
  
  const onFetch = () => {
    const domain = window.location.hostname
    if (domain !== 'app.hendricks.so') {
      // Try to pull from cookies first
      const logoURL = getLogoURL()
      if (logoURL) {
        console.log('Logo found in cookies', logoURL)
        setLogo(logoURL)
        return
      }

      // Pull from API
      getLogoByDomain(domain).then((response) => {
        console.log(response)
        if (response.success) {
          const newDomain = 'https://api.hendricks.so/public/white-labelling/logo?domain=' + domain
          setLogo(newDomain)
          setLogoURL(newDomain)
          console.log('Logo found and set to ', newDomain)
        } else if (response.statusCode === 404) {
          console.log('No logo found')
          setLogo(null)
        }
      })
    } else {
      console.log('No logo on app.hendricks.so', domain)
      setLogo(null)
    }
  }

  useEffect(() => {
    onFetch()
  }, [])

  let fontSize = 32
  if (size === 'sm') {
    fontSize = 16
  } else if (size === 'md') {
    fontSize = 20
  }

  console.log('logo', logo)
  if (logo && logo !== '') {
    return (
      <div className="logo-wrapper" style={style}>
        <img src={logo} alt="logo" className="logo-image" />
        <p className="powered-by">Powered by <span className="powered-by-hendricks">hendricks</span></p>
      </div>
    )
  }

  return (
    <div className="logo-wrapper" style={style}>
      <h1 className="title" style={{ fontSize }}>
        hendricks
      </h1>
    </div>
  )
}

export default Logo
