import { get, patch, post } from './general'

export const createWhiteLabeling = (body) => {
  return post(`/white-labelling`, {}, body)
}

export const listWhiteLabeling = (params) => {
  return get(`/white-labelling`, {}, params)
}

export const createWhiteLabelingDeployment = (entryId) => {
  return post(`/white-labelling/${entryId}/deploy`, {}, {})
}

export const getWhiteLabeling = (entryId, params) => {
  return get(`/white-labelling/${entryId}`, {}, params)
}

export const verifyDomainForWhiteLabelling = (entryId) => {
  return post(`/white-labelling/${entryId}/verify-domain`, {}, {})
}

export const getWhiteLabellingLogo = (entryId) => {
  return get(`/white-labelling/${entryId}/logo`, {}, {})
}

export const uploadLogo = (entryId, body) => {
  return post(`/white-labelling/${entryId}/logo`, {}, body)
}

export const getLogoByDomain = (domain) => {
  // We don't actually want to call this in a GET request. We just
  // want to confirm it returns a 200. For loading the image,
  // just call from src={}.
  return get(`/public/white-labelling/logo`, {
    'Accept': 'skip',
  }, { domain })
}

export const updateWhiteLabelling = (entryId, body) => {
  return patch(`/white-labelling/${entryId}`, {}, body)
}

export const getPartnerStatusForWhiteLabelling = (params) => {
  return get(`/white-labelling/partner-status`, {}, params)
}

export const sendPartnerInviteForWhiteLabelling = (params) => {
  return post(`/white-labelling/partner-invite`, {}, params)
}