// SettingsWhiteLabelingIndividualPage
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  ChevronLeft,
  ChevronRight,
} from 'lucide-react'

import SettingsPage from '../SettingsPage'
import { getMe } from 'utils/api/users'
import {
  getWhiteLabeling,
  verifyDomainForWhiteLabelling,
  getWhiteLabellingLogo,
  uploadLogo,
  updateWhiteLabelling,
} from 'utils/api/white-labeling'

import './SettingsWhiteLabelingPage.css'
import './SettingsWhiteLabelingIndividualPage.css'
import './SettingsWhiteLabelingIndividualPageApps.css'
import { listApps } from 'utils/api/apps'

const AppTableRow = ({
  item,
  entry,
  selectedApps,
  setSelectedApps,
  action,
  setAction,
}) => {
  const apps = entry.availableApps || {}
  const isSelected = selectedApps.includes(item.id)

  const onSelected = (e) => {
    // e.preventDefault() // Don't put!
    if (e.target.checked && !isSelected) {
      setSelectedApps([...selectedApps, item.id])

      if (!action || action === '') {
        setAction(apps[item.id] ? 'makeUnavailable' : 'makeAvailable')
      }
    } else if (!e.target.checked && isSelected) {
      setSelectedApps(selectedApps.filter((x) => x !== item.id))
    }
  }

  return (
    <tr>
      <td style={{ width: 50 }}>
        <input
          type="checkbox"
          id={`app-${item.id}`}
          name={`app-${item.id}`}
          className="form-check-input"
          checked={!!isSelected}
          defaultChecked={!!isSelected}
          // value={isSelected ? 'true' : 'false'}
          onChange={onSelected}
        />
      </td>
      <td>
        <img
          src={item.iconUrl}
          alt="App"
          className="white-labelling-apps-settings-icon"
        />
        <span className="settings-app-table-row-app-name">{item.name}</span>
      </td>
      <td>
        {apps[item.id] ? (
          <span className="badge rounded-pill badge-subtle-success">
            Selected
          </span>
        ) : (
          <span className="badge rounded-pill badge-subtle-info">
            Available
          </span>
        )}
      </td>
    </tr>
  )
}

const AppsSection = ({ entry, onFetch }) => {
  const [action, setAction] = useState('')
  const [selectedApps, setSelectedApps] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([])
  const [error, setError] = useState(null)

  const onFetchApps = () => {
    setLoading(true)

    listApps({
      page,
      limit: 5,
    }).then((response) => {
      if (response.success) {
        setResults(response.data.apps || [])
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    onFetchApps()
  }, [])

  useEffect(() => {
    onFetchApps()
  }, [page])

  const onMakeAvailable = (e) => {
    e.preventDefault()
    if (selectedApps.length === 0) {
      setError('Please select at least one app to adjust.')
      return
    }

    updateWhiteLabelling(entry.id, {
      appIds: selectedApps,
      action,
    }).then((response) => {
      if (response.success) {
        setSelectedApps([])
        setAction('')
        onFetch()
      }
    })
  }

  return (
    <>
      <p className="white-labelling-settings-section-title">Apps</p>
      <p className="text-muted">
        You can manage what apps can be accessed by your vendors.
      </p>
      <br />
      <div>
        <div>
          {action === 'makeAvailable' ? (
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={onMakeAvailable}
            >
              Make Available ({selectedApps.length})
            </button>
          ) : action === 'makeUnavailable' ? (
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={onMakeAvailable}
            >
              Make Unavailable ({selectedApps.length})
            </button>
          ) : (
            <button
              className="btn btn-sm btn-outline-secondary"
              disabled
              style={{
                backgroundColor: '#f1f1f1',
                borderColor: '#d8d8d8',
                color: '#BDBDBD',
              }}
            >
              Make Available
            </button>
          )}
        </div>
        <table className="table w-100" style={{ maxWidth: 600 }}>
          <thead>
            <tr>
              <th style={{ width: 50 }}></th>
              <th>Details</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </td>
              </tr>
            ) : results.length == 0 ? (
              <tr>
                <td colSpan={3} style={{ textAlign: 'center' }}>
                  No apps available.
                </td>
              </tr>
            ) : (
              results.map((result) => (
                <AppTableRow
                  key={result.id}
                  item={result}
                  entry={entry}
                  selectedApps={selectedApps}
                  setSelectedApps={setSelectedApps}
                  action={action}
                  setAction={setAction}
                />
              ))
            )}
          </tbody>
        </table>
        <ul className="pagination pagination-sm">
          <li className={`page-item ${page == 1 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              href="#"
              tabIndex="-1"
              aria-disabled={page == 1 ? 'true' : 'false'}
              style={{ padding: 2, fontSize: 12 }}
              disabled={page == 1}
              onClick={(e) => setPage(page - 1)}
            >
              <ChevronLeft size={10} />
            </button>
          </li>
          <li className="page-item">
            <a className="page-link" href="#">
              {page}
            </a>
          </li>
          <li className={`page-item ${results.length < 5 ? 'disabled' : ''}`}>
            <button
              className="page-link"
              href="#"
              style={{ padding: 2, fontSize: 12 }}
              disabled={results.length < 5}
              onClick={(e) => setPage(page + 1)}
            >
              <ChevronRight size={10} />
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

const getPrefixOfSubdomain = (subdomain) => {
  const parts = subdomain.split('.')
  if (parts.length === 1) {
    return ''
  }
  return parts.slice(0, parts.length - 2).join('.')
}

const UploadLogoForm = ({ entry, onFetch }) => {
  const [uploading, setUploading] = useState(false)
  const [logo, setLogo] = useState(null)

  const onChange = (e) => {
    setLogo(e.target.files[0])
  }

  const onSubmitUploadLogo = (e) => {
    e.preventDefault()
    setUploading(true)

    // Convert to base64
    const reader = new FileReader()
    reader.readAsDataURL(logo)
    reader.onload = () => {
      const base64Text = reader.result.split(',')[1]

      // Upload
      uploadLogo(entry.id, {
        logo: base64Text,
        fileName: logo.name,
        mimeType: logo.type,
      }).then((response) => {
        setUploading(false)
        if (response.success) {
          onFetch()
        }
      })
    }
  }

  return (
    <div>
      <form onSubmit={onSubmitUploadLogo}>
        <div className="form-group logo-form-group">
          <input
            type="file"
            className="form-control"
            id="logo"
            accept="image/*"
            onChange={onChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          {uploading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
              style={{ marginTop: 3 }}
            ></span>
          ) : (
            <>Save</>
          )}
        </button>
      </form>
    </div>
  )
}

const SettingsWhiteLabelingIndividualPage = () => {
  const [loading, setLoading] = useState(true)
  const [isNotPro, setIsNotPro] = useState(false)
  const [verifying, setVerifying] = useState(false)
  const [verified, setVerified] = useState(true)
  const [entry, setEntry] = useState(null)
  const [loadingEntry, setLoadingEntry] = useState(true)

  // Get id from params
  const params = useParams()
  const id = params.id

  const onFetch = () => {
    getWhiteLabeling(id).then((response) => {
      console.log('response', response.data)
      if (response.success) {
        setEntry(response.data.entry)
        setVerified(response.data.entry.customDomainVerified)
      }
      setLoadingEntry(false)
    })
  }

  useEffect(() => {
    getMe().then((response) => {
      if (response.success) {
        if (response.data.company.plan === 'basic') {
          setIsNotPro(true)
        }
        setLoading(false)
      }
    })

    onFetch()
  }, [])

  const onVerify = (e) => {
    e.preventDefault()
    setVerifying(true)
    verifyDomainForWhiteLabelling(id).then((response) => {
      if (response.success) {
        onFetch()
      }
      setVerifying(false)
    })
  }

  if (loading || loadingEntry) {
    return <SettingsPage loading={true} />
  }

  if (isNotPro) {
    return (
      <SettingsPage loading={false}>
        <h2 className="settings-section-title">White Labelling</h2>
        <p className="settings-section-title-subtext">
          Dedicated vendor portal with your logo, colors, and domain.
        </p>
        <div className="white-labeling-not-available">
          <p>
            <strong>White Labelling</strong> is available on the Pro plan.
            <br />
            Upgrade to Pro to unlock this feature.
          </p>
        </div>
      </SettingsPage>
    )
  }

  return (
    <SettingsPage loading={false}>
      <h2 className="settings-section-title">White Labelling</h2>
      <p className="settings-section-title-subtext">
        Dedicated vendor portal with your logo, colors, and domain.
      </p>
      <p>
        <a href="/settings/white-labelling">
          <ChevronLeft size={16} /> Back
        </a>
      </p>
      <br />
      <p className="white-labelling-settings-domain">
        <b>Domain:</b> <code>{entry.domain}</code>
      </p>
      {verified ? (
        <div className="domain-verified">
          <CheckCircleIcon size={16} /> Domain Verified
        </div>
      ) : (
        <div className="domain-instructions">
          <p>
            Add a <code>CNAME</code> record for{' '}
            <code>{getPrefixOfSubdomain(entry.domain)}</code> pointing to{' '}
            <code>{entry.cnameUrl}</code>
          </p>
          <button className="btn btn-sm btn-outline-primary" onClick={onVerify}>
            {verifying ? (
              <span>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginTop: 3 }}
                ></span>{' '}
                <span style={{ top: -3, position: 'relative' }}>Verifying</span>
              </span>
            ) : (
              'Verify Domain'
            )}
          </button>
        </div>
      )}
      <p className="white-labelling-settings-section-title">Logo</p>
      <p className="text-muted">
        Your logo will be displayed in the vendor portal. We recommend 200 px x
        50 px.
      </p>

      {entry.logoUrl !== '' ? (
        <div>
          <p>Current:</p>
          <img
            src={entry.logoUrl}
            alt="Logo"
            className="white-labelling-settings-logo"
          />
        </div>
      ) : (
        <p>No logo uploaded.</p>
      )}

      <UploadLogoForm entry={entry} onFetch={onFetch} />
      <br />
      <AppsSection entry={entry} onFetch={onFetch} />
    </SettingsPage>
  )
}

export default SettingsWhiteLabelingIndividualPage
