import React, { useState, useEffect } from 'react'
import { InlineWidget } from 'react-calendly'
import { BookCopy, MapPin, MessagesSquare } from 'lucide-react'

import Switch from 'components/Switch/Switch'
import Textbox from 'components/Textbox/Textbox'
import TextboxError from 'components/Textbox/TextboxError'

import { updateSharedEDIV1Details } from 'utils/api/internal-apps'

import './ConnectedWith.css'
import './EDIDashboard.css'
import './Controls.css'
import './SFTPConnection.css'
import { timeSince } from 'utils/date'

const ConnectedWith = ({ name }) => {
  const [saving, setSaving] = useState(false)
  const [showNo, setShowNo] = useState(false)
  const [showYes, setShowYes] = useState(false)

  if (showYes) {
    return (
      <div className="connected-with-wrapper">
        <h2>
          Great! Let's set up some time to get started with connecting your
          systems to {name}.
        </h2>
        <p style={{ marginBottom: 20 }}>
          Please find some time that works for you below. If you have any
          questions, or need help with anything, feel free to reach out to us.
        </p>
        <InlineWidget url="https://calendly.com/hello-hendricks/30min" />
      </div>
    )
  }
  if (showNo) {
    return (
      <div className="connected-with-wrapper">
        <p>
          Start by connecting with {name} to get started. Once you've made
          contact with then, we can help guide you through the EDI portion of
          the process. If you can need any questions, or have any issues, feel
          free to reach out to us.
        </p>
      </div>
    )
  }

  return (
    <div className="connected-with-wrapper">
      <h2>Have you already connected with {name}?</h2>
      <div>
        <button
          className="btn btn-outline-secondary"
          onClick={() => setShowYes(true)}
        >
          Yes
        </button>
        <button
          className="btn btn-outline-secondary"
          onClick={() => setShowNo(true)}
        >
          No
        </button>
      </div>
    </div>
  )
}

const getDescription = (key, name) => {
  const descriptions = {
    sendInventory: 'Send inventory data for each variant and per location.',
    receivedOrders: `Receive new orders from ${name}.`,
    sendAsns: 'Send fulfillment information for orders.',
    sendInvoices: 'Send invoices for fulfilled orders.',
    sendPoAcknowledgements:
      'Send back the accept or denied of purchase orders.',
    sendMotorCarrierLoadTender: 'Send Motor Carrier Load Tender.',
    sendShipmentStatus: 'Send fulfillment updates.',
    receiveElectronicFundTransfer: 'Receive Electronic Fund Transfer.',
    sendProductActivityData: 'Send product activtiy data with EDI files.',
    sendProductsCSV: 'Send product information with CSV files.',
    sendInventoryCSV: 'Send inventory information with CSV.',
  }

  return descriptions[key]
}

const ControlRow = ({
  appDetails,
  details,
  rowKey,
  name,
  fetchDetails,
  companySlug,
  viewToken,
}) => {
  const key = rowKey
  const [isOn, setIsOn] = useState(
    (details.controls[key] && appDetails[key].available) || false
  )

  const onToggle = (e) => {
    e.preventDefault()
    setIsOn(!isOn)
    updateSharedEDIV1Details(companySlug, viewToken, {
      [key]: !isOn,
    }).then((response) => {
      if (response.success) {
        fetchDetails()
      } else {
        setIsOn(!isOn)
      }
    })
  }

  return (
    <tr>
      <td style={{ width: 50 }}>
        <Switch value={isOn} onChange={onToggle} />
      </td>
      <td>
        <h4>{appDetails[key].label}</h4>
        <p>{getDescription(key, name)}</p>
        {!appDetails[key].available && appDetails[key].reason !== '' && (
          <span className="badge badge-subtle-secondary">
            <strong>Disabed:</strong> {appDetails[key].reason}
          </span>
        )}
      </td>
      <td>
        {details.controls[key] && appDetails[key].available ? (
          <span className="badge bg-success">Enabled</span>
        ) : appDetails[key].available ? (
          <span className="badge bg-warning">Ready</span>
        ) : (
          <span className="badge bg-danger">Disabled</span>
        )}
      </td>
      <td>
        <a href="#">View</a>
      </td>
    </tr>
  )
}

const DisplaySectionOfControls = ({
  section,
  appDetails,
  name,
  details,
  viewToken,
  companySlug,
  fetchDetails,
  showAvailable,
}) => {
  const keys = [
    'sendInventory',
    'receivedOrders',
    'sendAsns',
    'sendInvoices',
    'sendPoAcknowledgements',
    'sendMotorCarrierLoadTender',
    'sendShipmentStatus',
    'receiveElectronicFundTransfer',
    'sendProductActivityData',
    'sendProductsCSV',
    'sendInventoryCSV',
  ]
  const sectionTitles = {
    products_and_inventory: 'Products & Inventory',
    orders: 'Orders',
    fulfillments: 'Fulfillments',
    invoices: 'Invoices',
  }
  const filteredKeys = keys.filter(
    (key) =>
      appDetails[key].section === section &&
      (appDetails[key].available || showAvailable)
  )
  // Sort by priority
  filteredKeys.sort((a, b) => appDetails[a].priority - appDetails[b].priority)

  if (filteredKeys.length === 0) {
    return null
  }

  return (
    <div>
      <h3 className="controls-section-title">
        {sectionTitles[section] || section}
      </h3>
      <table className="table">
        <tbody>
          {filteredKeys.map((key) => (
            <ControlRow
              key={key}
              rowKey={key}
              appDetails={appDetails}
              details={details}
              name={name}
              viewToken={viewToken}
              companySlug={companySlug}
              fetchDetails={fetchDetails}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const Controls = ({
  appDetails,
  name,
  details,
  viewToken,
  companySlug,
  fetchDetails,
}) => {
  const sections = [
    'products_and_inventory',
    'orders',
    'fulfillments',
    'invoices',
  ]
  return (
    <div>
      <h2 className="edi-dashboard-h2">Controls</h2>
      <p className="text-muted">
        Manage the data that flows between your systems and {name}.
      </p>
      {details.controls.lastSyncedReceivedFilesAt && (
        <p>
          Last Synced Received Files:{' '}
          {timeSince(new Date(details.controls.lastSyncedReceivedFilesAt))} ago
        </p>
      )}
      <br />
      {sections.map((section) => (
        <DisplaySectionOfControls
          section={section}
          appDetails={appDetails}
          name={name}
          details={details}
          key={section}
          viewToken={viewToken}
          companySlug={companySlug}
          fetchDetails={fetchDetails}
        />
      ))}
    </div>
  )
}

const EditSFTPConnection = ({
  appDetails,
  name,
  details,
  onToggleEditMode,
  fetchDetails,
  companySlug,
  viewToken,
}) => {
  const [error, setError] = useState('')
  const [host, setHost] = useState(details.sftp.host || '')
  const [port, setPort] = useState(details.sftp.port || '')
  const [userName, setUserName] = useState(details.sftp.userName || '')
  const [password, setPassword] = useState(details.sftp.password || '')
  const [readFolder, setReadFolder] = useState(details.sftp.readFolder || '')
  const [writeFolder, setWriteFolder] = useState(details.sftp.writeFolder || '')
  const [saving, setSaving] = useState(false)

  const onSave = (e) => {
    e.preventDefault()
    if (host === '') {
      setError('Host is required')
      return
    }
    if (port === '') {
      setError('Port is required')
      return
    }
    if (userName === '') {
      setError('Username is required')
      return
    }
    if (password === '') {
      setError('Password is required')
      return
    }
    if (readFolder === '') {
      setError('Read Directory is required')
      return
    }
    if (writeFolder === '') {
      setError('Write Directory is required')
      return
    }

    // Convert port to be a number
    const portNumber = parseInt(port, 10)
    if (isNaN(portNumber)) {
      setError('Port must be a number')
      return
    }
    setSaving(true)

    const payload = {
      host,
      port: portNumber,
      userName,
      password,
      readFolder,
      writeFolder,
    }
    updateSharedEDIV1Details(companySlug, viewToken, payload).then(
      (response) => {
        if (response.success) {
          setSaving(false)
          if (fetchDetails) {
            fetchDetails(() => {
              onToggleEditMode()
            })
          } else {
            onToggleEditMode()
          }
        }
      }
    )
  }

  return (
    <>
      <div className="edit-sftp-connection-host-row">
        <Textbox
          label="Host"
          value={host}
          onChange={(e) => setHost(e.target.value)}
          placeholder="Host"
        />
        <Textbox
          label="Port"
          value={port}
          onChange={(e) => setPort(e.target.value)}
          placeholder="Port"
          textboxStyle={{ width: 100 }}
        />
      </div>
      <Textbox
        label="Username"
        value={userName}
        onChange={(e) => setUserName(e.target.value)}
        placeholder="Username"
      />
      <Textbox
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <Textbox
        label="Read Directory"
        value={readFolder}
        onChange={(e) => setReadFolder(e.target.value)}
        placeholder="Read Directory"
      />
      <Textbox
        label="Write Directory"
        value={writeFolder}
        onChange={(e) => setWriteFolder(e.target.value)}
        placeholder="Write Directory"
      />
      <div>
        {error && error !== '' && <TextboxError>{error}</TextboxError>}
        <button
          className="btn btn-outline-primary"
          onClick={onSave}
          disabled={saving}
        >
          {saving ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            'Save'
          )}
        </button>
        <button className="sftp-edit-btn" onClick={onToggleEditMode}>
          Cancel
        </button>
      </div>
    </>
  )
}

const SFTPConnection = ({
  appDetails,
  name,
  details,
  fetchDetails,
  companySlug,
  viewToken,
}) => {
  const [editMode, setEditMode] = useState(false)

  const onToggleEditMode = (e) => {
    e.preventDefault()
    setEditMode(!editMode)
  }

  return (
    <div>
      <h2 className="edi-dashboard-h2">SFTP Connection</h2>
      <p className="text-muted">
        Use the following information to connect to {name} via SFTP. This is how
        EDI and CSV files will be exchanged.
      </p>
      <div className="sftp-connection-wrapper">
        {editMode ? (
          <EditSFTPConnection
            appDetails={appDetails}
            name={name}
            details={details}
            onToggleEditMode={onToggleEditMode}
            fetchDetails={fetchDetails}
            companySlug={companySlug}
            viewToken={viewToken}
          />
        ) : (
          <>
            <div>
              {details.sftp.ready ? (
                <span className="badge rounded-pill badge-subtle-success">
                  Connected
                </span>
              ) : (
                <span className="badge rounded-pill badge-subtle-danger">
                  Not Connected
                </span>
              )}

              <button className="sftp-edit-btn" onClick={onToggleEditMode}>
                Edit
              </button>
            </div>
            <p>
              <b>Host:</b> {details.sftp.host || '(No Host)'}
            </p>
            <p>
              <b>Port:</b> {details.sftp.port || '(No Port)'}
            </p>
            <p>
              <b>Username:</b> {details.sftp.userName || '(No Username)'}
            </p>
            <p>
              <b>Password:</b> {details.sftp.visiblePassword || '(No Password)'}
            </p>
            <p>
              <b>Read Directory:</b>
              {details.sftp.readFolder || '(No Directory to Read Files Set)'}
            </p>
            <p>
              <b>Write Directory:</b>
              {details.sftp.writeFolder || '(No Directory to Write Files Set)'}
            </p>
          </>
        )}
      </div>
    </div>
  )
}

const EDIDashboard = ({
  companySlug,
  name,
  viewToken,
  details,
  appDetails,
  fetchDetails,
}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-8">
          {details && details.status && details.status.alreadyConnected ? (
            <>
              <SFTPConnection
                appDetails={appDetails}
                name={name}
                details={details}
                fetchDetails={fetchDetails}
                companySlug={companySlug}
                viewToken={viewToken}
              />
              <hr />
              <Controls
                appDetails={appDetails}
                name={name}
                details={details}
                viewToken={viewToken}
                companySlug={companySlug}
                fetchDetails={fetchDetails}
              />
            </>
          ) : (
            <>
              <ConnectedWith name={name} />
              <hr />
              <h2 className="edi-dashboard-h2">Support</h2>
              <p>
                We can be reached at <code>hello@hendricks.so</code>. Just let
                us know what you need help with, and we'll get back to you.
              </p>
            </>
          )}
        </div>
        <div className="col-4">
          <p className="edi-dashboard-right-description">
            {appDetails && appDetails.description}
          </p>

          {appDetails && appDetails.hqLocation !== '' && (
            <div className="edi-dashboard-right-item">
              <div className="edi-dashboard-right-title-wrapper">
                <MapPin color="rgb(63, 128, 234)" />
                <p className="edi-dashboard-right-title">HQ Location</p>
              </div>
              <p>{appDetails.hqLocation}</p>
            </div>
          )}

          <div className="edi-dashboard-right-item">
            <div className="edi-dashboard-right-title-wrapper">
              <MessagesSquare color="rgb(63, 128, 234)" />
              <p className="edi-dashboard-right-title">Hendricks Rep</p>
            </div>
            <p>{appDetails && appDetails.rep ? appDetails.rep : ''}</p>
          </div>

          <div className="edi-dashboard-right-item">
            <div className="edi-dashboard-right-title-wrapper">
              <MessagesSquare color="rgb(63, 128, 234)" />
              <p className="edi-dashboard-right-title">Support</p>
            </div>
            <p>
              We can be reached at{' '}
              <code className="edi-dashboard-right-code">
                hello@hendricks.so
              </code>
              . Just let us know what you need help with, and we'll get back to
              you.
            </p>
          </div>

          <div className="edi-dashboard-right-item">
            <div className="edi-dashboard-right-title-wrapper">
              <BookCopy color="rgb(63, 128, 234)" />
              <p className="edi-dashboard-right-title">Resources</p>
            </div>
            <ul style={{ marginTop: 10 }}>
              <li>
                <a href="#">Resource 1</a>
              </li>
              <li>
                <a href="#">Resource 2</a>
              </li>
              <li>
                <a href="#">Resource 3</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EDIDashboard
