import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SettingsPage from '../SettingsPage'
import { getMe } from 'utils/api/users'
import {
  createWhiteLabeling,
  listWhiteLabeling,
  createWhiteLabelingDeployment,
  getWhiteLabeling,
} from 'utils/api/white-labeling'

import './SettingsWhiteLabelingPage.css'
import { ArrowLeft, List } from 'lucide-react'
import Textbox from 'components/Textbox/Textbox'

const EntryTableRow = ({ entry }) => {
  const navigate = useNavigate()
  const [deploying, setDeploying] = useState(false)
  const [item, setItem] = useState(entry)

  useEffect(() => {
    // Refresh the status of the entry on change every 1 minute
    const interval = setInterval(() => {
      onRefreshByStatus()
    }, 10000)

    return () => clearInterval(interval)
  }, [])

  const onRefreshByStatus = () => {
    getWhiteLabeling(entry.id).then((response) => {
      console.log('onRefreshByStatus', item.status, response.data)
      if (response.success && response.data.entry.status !== item.status) {
        setItem({
          ...response.data.entry,
        })
      }
    })
  }

  const onDeploy = (e) => {
    e.preventDefault()
    console.log('onDeploy', entry.id)
    setDeploying(true)
    createWhiteLabelingDeployment(entry.id).then((response) => {
      if (response.success) {
        setItem({
          ...response.data.entry,
        })
      }
      setDeploying(false)
    })
  }

  const onEdit = (e, entryId) => {
    e.preventDefault()
    // Redirect
    navigate(`/settings/white-labelling/${entryId}`)
  }

  return (
    <tr key={item.id}>
      <td>{item.domain}</td>
      <td style={{ width: 100 }}>
        {item.status === 'deployed' ? (
          <span className="badge rounded-pill badge-subtle-success">
            Deployed
          </span>
        ) : item.status === 'deploying' ? (
          <span className="badge rounded-pill badge-subtle-primary">
            Deploying...
          </span>
        ) : item.status === 'failed' ? (
          <span className="badge rounded-pill badge-subtle-danger">Failed</span>
        ) : null}
      </td>
      <td style={{ width: 200 }}>
        {item.status !== 'deploying' && (
          <button
            className="btn btn-outline-primary"
            style={{ marginRight: 5 }}
            onClick={onDeploy}
            disabled={deploying}
          >
            {deploying ? 'Deploying...' : 'Deploy'}
          </button>
        )}
        <button
          className="btn btn-outline-primary"
          onClick={(e) => onEdit(e, item.id)}
        >
          Edit
        </button>
      </td>
    </tr>
  )
}

const ListEntries = () => {
  const [mode, setMode] = useState('')
  const [entries, setEntries] = useState([])
  const [newDomain, setNewDomain] = useState('')
  const [adding, setAdding] = useState(false)

  const [deploymentId, setDeploymentId] = useState(null)
  const [entryIdForDeployment, setEntryIdForDeployment] = useState(null)

  useEffect(() => {
    onFetch()
  }, [])

  const onAddMode = (e) => {
    e.preventDefault()

    setMode('add')
  }

  const onAdd = (e) => {
    e.preventDefault()

    setAdding(true)
    createWhiteLabeling({
      domain: newDomain,
    }).then((response) => {
      if (response.success) {
        console.log('response', response)
        setEntries([...entries, response.data.entry])
        setNewDomain('')
        if (response.data.deploymentId !== '') {
          setDeploymentId(response.data.deploymentId)
          setEntryIdForDeployment(response.data.entry.id)
          setMode('deploy')
        } else {
          setMode('')
        }
      }
    })
  }

  const onFetch = () => {
    listWhiteLabeling().then((response) => {
      if (response.success) {
        setEntries(response.data.entries || [])
      }
    })
  }

  if (mode === 'add') {
    return (
      <div>
        <button className="btn btn-link" onClick={() => setMode('')}>
          <ArrowLeft size={16} style={{ marginRight: 5 }} />
          Back
        </button>
        <div className="card" style={{ maxWidth: 400 }}>
          <div className="card-body">
            <form>
              <p style={{ marginBottom: 10 }}>
                <b>Add White Label Property</b>
              </p>
              <Textbox
                label="Domain"
                placeholder="vendor.yoursite.com"
                value={newDomain}
                onChange={(e) => setNewDomain(e.target.value)}
                textboxStyle={{ maxWidth: 300 }}
              />
              <button
                type="submit"
                className="btn btn-primary"
                onClick={onAdd}
                disabled={adding}
              >
                {adding ? 'Adding...' : 'Add'}
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <button className="btn btn-primary" onClick={onAddMode}>
          Add Domain
        </button>
      </div>

      <br />
      <table className="table w-table" style={{ maxWidth: 500 }}>
        <thead>
          <tr>
            <th>Domain</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(entries || []).length > 0 ? (
            entries.map((entry) => (
              <EntryTableRow key={entry.id} entry={entry} />
            ))
          ) : (
            <tr>
              <td colSpan={2} style={{ textAlign: 'center' }}>
                <List size={24} />
                <p style={{ marginTop: 10 }}>No entries</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

const SettingsWhiteLabelingPage = () => {
  const [loading, setLoading] = useState(true)
  const [isNotPro, setIsNotPro] = useState(false)

  useEffect(() => {
    getMe().then((response) => {
      if (response.success) {
        if (response.data.company.plan === 'basic') {
          setIsNotPro(true)
        }
        setLoading(false)
      }
    })
  }, [])

  if (loading) {
    return <SettingsPage loading={true} />
  }

  if (isNotPro) {
    return (
      <SettingsPage loading={false}>
        <h2 className="settings-section-title">White Labelling</h2>
        <p className="settings-section-title-subtext">
          Dedicated vendor portal with your logo, colors, and domain.
        </p>
        <div className="white-labeling-not-available">
          <p>
            <strong>White Labelling</strong> is available on the Pro plan.
            <br />
            Upgrade to Pro to unlock this feature.
          </p>
        </div>
      </SettingsPage>
    )
  }

  return (
    <SettingsPage loading={false}>
      <h2 className="settings-section-title">White Labelling</h2>
      <p className="settings-section-title-subtext">
        Dedicated vendor portal with your logo, colors, and domain.
      </p>
      <ListEntries />
    </SettingsPage>
  )
}

export default SettingsWhiteLabelingPage
