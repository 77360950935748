import { useState, useEffect } from 'react'
import {
  getPartnerStatusForWhiteLabelling,
  sendPartnerInviteForWhiteLabelling,
} from 'utils/api/white-labeling'

import './WhiteLabellingPartnerStatus.css'
import { updateCurrentOrganization } from 'utils/api/organizations'

const PartnerAlert = ({ children }) => (
  <div className="left-menu-white-labelling-partner-status">{children}</div>
)

const WhiteLabellingPartnerStatus = ({ currentCompany }) => {
  const [status, setStatus] = useState('')
  const [name, setName] = useState('')
  const [sendingInvite, setSendingInvite] = useState(false)

  const onEnableViewPartners = () => {
    if (!currentCompany) {
      return
    }

    updateCurrentOrganization(currentCompany.id, {
      hidePartners: false,
      hideProductDiscovery: false,
    }).then((response) => {
      console.log('updateCurrentOrganization', response)
    })
  }

  const onFetch = () => {
    if (window.location.hostname === 'app.hendrick.so') {
      onEnableViewPartners()
      return
    }
    if (window.location.hostname === 'localhost') {
      return
    }

    getPartnerStatusForWhiteLabelling({
      domain: window.location.hostname,
    }).then((response) => {
      console.log('getPartnerStatusForWhiteLabelling', response)
      if (response.success) {
        setStatus(response.data.status)
        setName(response.data.name)
      }
    })
  }

  useEffect(() => {
    onFetch()
  }, [])

  const onSendInvite = (e) => {
    e.preventDefault()

    setSendingInvite(true)
    sendPartnerInviteForWhiteLabelling({
      domain: window.location.hostname,
    }).then((response) => {
      console.log('sendPartnerInviteForWhiteLabelling', response)
      if (response.success) {
        onFetch()
      }
      setSendingInvite(false)
    })
  }

  if (status === '') {
    return null
  }

  if (status == 'partner_inactive' && name !== '') {
    return (
      <PartnerAlert>
        <p>Your partnership with {name} is inactive.</p>
      </PartnerAlert>
    )
  } else if (status == 'partner_inactive') {
    return (
      <PartnerAlert>
        <p>Your partnership is inactive.</p>
      </PartnerAlert>
    )
  }

  if (status == 'partner_no_pricing' && name !== '') {
    return (
      <PartnerAlert>
        <p>
          Your partnership with {name} is missing pricing. Navigate to pricing
          and attach to your partnership with {name}.
        </p>
      </PartnerAlert>
    )
  } else if (status == 'partner_no_pricing') {
    return <PartnerAlert>Your partnership is not priced.</PartnerAlert>
  }

  if (status == 'invite_pending' && name !== '') {
    return (
      <PartnerAlert>
        <p>
          Your partnership with {name} is pending. Please wait for the partner
          to accept the invite.
        </p>
      </PartnerAlert>
    )
  } else if (status == 'invite_pending') {
    return (
      <PartnerAlert>
        <p>Your partnership is pending.</p>
      </PartnerAlert>
    )
  }

  if (status == 'invite_not_sent' && name !== '') {
    return (
      <PartnerAlert>
        <p>No invite sent for {name}. Please send an invite to the partner.</p>
        <button
          className="btn btn-outline-primary"
          onClick={onSendInvite}
          disabled={sendingInvite}
        >
          {sendingInvite ? 'Sending...' : 'Send Invite'}
        </button>
      </PartnerAlert>
    )
  } else if (status == 'invite_not_sent') {
    return (
      <PartnerAlert>
        <p>
          No invite sent for this partnership. Please send an invite to the
          partner.
        </p>
        <button
          className="btn btn-outline-primary"
          onClick={onSendInvite}
          disabled={sendingInvite}
        >
          {sendingInvite ? 'Sending...' : 'Send Invite'}
        </button>
      </PartnerAlert>
    )
  }

  if (status == 'partner_connected' && name !== '') {
    return (
      <PartnerAlert>
        <p>Your partnership with {name} is active.</p>
      </PartnerAlert>
    )
  } else if (status == 'partner_connected') {
    return (
      <PartnerAlert>
        <p>Your partnership is active.</p>
      </PartnerAlert>
    )
  }

  return null
}

export default WhiteLabellingPartnerStatus
