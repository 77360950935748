import Modal from 'components/Modal/Modal'
import React, { useState, useEffect } from 'react'

import {
  listEDIFilesForSharedEDI,
  deleteEDIFilesForSharedEDI,
} from 'utils/api/internal-apps'
import { displayDate } from 'utils/date'

const LIMIT = 20

const EDITableRow = ({ item, companySlug, viewToken, fetchEDIFiles }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const onDelete = (e) => {
    e.preventDefault()
    setDeleting(true)
    deleteEDIFilesForSharedEDI(companySlug, viewToken, item.id, {}).then(
      (response) => {
        if (response.success) {
          fetchEDIFiles()
        }
        setDeleting(false)
      }
    )
  }

  return (
    <>
      <tr>
        <td>{item.fileName}</td>
        <td>
          {item.status === '' || item.status === 'pending' ? (
            <span className="badge rounded-pill badge-subtle-warning">
              Pending
            </span>
          ) : item.status === 'error' ? (
            <span className="badge rounded-pill badge-subtle-danger">
              Error
            </span>
          ) : item.status === 'completed' ? (
            <span className="badge rounded-pill badge-subtle-success">
              Processed
            </span>
          ) : (
            <span className="badge rounded-pill badge-subtle-info">
              {item.status}
            </span>
          )}
        </td>
        <td>{item.isaControlNum}</td>
        <td>
          {(item.relatedTransactions || []).length > 0 &&
            // Filter to max of 3 transaction sets
            item.relatedTransactions.slice(0, 3).map((ts) => (
              <span
                key={ts.id}
                className="badge rounded-pill badge-subtle-primary"
              >
                {ts.code}
              </span>
            ))(item.relatedTransactions.length > 3) && (
              <span className="badge rounded-pill badge-subtle-primary">
                +{item.relatedTransactions.length - 3}
              </span>
            )}
        </td>
        <td>{displayDate(item.createdAt)}</td>
        <td>
          <a href="#" className="btn btn-link">
            Download
          </a>
          <button
            className="btn btn-link"
            onClick={() => setShowDeleteModal(true)}
          >
            {(deleting && 'Deleting...') || 'Delete'}
          </button>
        </td>
      </tr>
      {showDeleteModal && (
        <Modal toggle={() => setShowDeleteModal(false)}>
          <div className="card-body">
            <p>
              Are you sure you want to delete this file? It may result in double
              processing if the file is still on the SFTP server. Consider
              downloading the file first, we cannot retrieve the file once it is
              deleted.
            </p>
            <br />
            <div>
              <button className="btn btn-primary" onClick={onDelete}>
                Delete
              </button>
              <button
                className="btn btn-link"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

const EDIFiles = ({ companySlug, viewToken }) => {
  const [page, setPage] = useState(1)
  const [results, setResults] = useState([])

  const fetchEDIFiles = () => {
    listEDIFilesForSharedEDI(companySlug, viewToken, {
      page,
      limit: LIMIT,
    }).then((response) => {
      if (response.success) {
        setResults(response.data.files || [])
      }
    })
  }

  useEffect(() => {
    fetchEDIFiles()
  }, [])

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Status</th>
            <th>ISA Control Number</th>
            <th>Transaction Sets</th>
            <th>Received</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {(results || []).length > 0 ? (
            results.map((result) => (
              <EDITableRow
                key={result.id}
                item={result}
                viewToken={viewToken}
                companySlug={companySlug}
                fetchEDIFiles={fetchEDIFiles}
              />
            ))
          ) : (
            <tr>
              <td colSpan="6">No records found</td>
            </tr>
          )}
        </tbody>
      </table>
      {page === 1 && results.length < LIMIT ? null : (
        <div className="pagination-wrapper-right">
          <ul className="pagination pagination-sm">
            <li className="page-item">
              <a className="page-link" href="#">
                Previous
              </a>
            </li>
            <li className="page-item active">
              <a className="page-link" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                Next
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default EDIFiles
